import React from 'react'
import Navbar from '../inc/Navbar'
import VisitShop from './VisitShop'
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Visitpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Darkblue" />
        <div className='makeupshopmurshidabad'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Customer Visit Shop Photo </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <VisitShop/>

    </div>
  )
}

export default Visitpage