import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import Makeup from './Makeup'
import "./Home.css";
import Reviews from './Reviews';
import VisitShop from './VisitShop';
import ScrollToTop from "react-scroll-to-top";
import Hair from './Hair';
import bannermainshop from "../images/main_shop 2024.jpg";
import model from "../images/model.jpeg";
import Makzamiamodel from "../images/makzamia model.jpeg";
import Brand from './Brand';
import YoutubeV from './YoutubeV';


function Home() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="Darkblue" />
            <div className='album py-1'>
                <div className='container '>
                    <h5>Queen Makeup & Nail</h5>
                </div>
            </div>
            <img src={bannermainshop} className="d-block w-100" alt="makeupbanner" />
            <div className="album py-4">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6">

                            <Slider />
                        </div>
                        <div className="col-md-6">

                            <div className="card" >
                                <img src={model} className="d-block w-100 mb-2" alt="model" />
                                <img src={Makzamiamodel} className="card-img-top" alt="Makzamiamodel"/>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='makeupberhampore'>
                <div className='album py-1'>
                    <div className='container '>

                        <marquee><h4 style={{ color: "white" }} className=' mt-2' >Welcome to Queen Makeup & Nail, Exclusive Pujo Collection</h4></marquee>
                    </div>
                </div>
            </div>
            <center>
<br/>
            <div className="album py-0">
                    <div className="container">
                        <h1 style={{ color: "grey" }} ><b >Our </b > Brand       </h1>
                    </div>
                </div>
                <Brand/>
                <YoutubeV/>
                <div className="album py-3">
                    <div className="container">
                        <h1 className='blink'><b>|| Exclusive Pujo Collection!</b></h1>
                    </div>
                </div>
            </center>

            <div className='backlight'>
                <br />
                <div className="album py-2">
                    <div className="container">
                        <h1 style={{ color: "grey" }} ><b >Explore </b > The New Arrival        </h1>
                    </div>
                </div>
                <div className='album py-2'>
                    <div className='container'>
                        <h1 style={{ color: "grey" }} ><b style={{ color: "deeppink" }}>MAKEUP </b > PRODUCTS       </h1>

                    </div>
                </div>

                {/* <div className='blackcolor'> */}
                <Makeup />
                <hr />
                <br />
                <div className='album py-2'>
                    <div className='container'>
                        <h1 style={{ color: "grey" }} ><b style={{ color: "deeppink" }}>HAIR </b > PRODUCTS       </h1>

                    </div>
                </div>
                <Hair />
                <br />
            </div>
            <div className='album py-2'>
                <div className='container'>
                    <h1><b>OUR REVIEWS</b></h1>
                </div>
            </div>

            <Reviews />
            <br />
            <hr />
            <br />
            <div className='album py-0'>
                <div className='container'>
                    <h1><b>CUSTOMER VISIT SHOP PHOTO</b></h1>
                </div>
            </div>
            <VisitShop />

        </div >
    )
}

export default Home