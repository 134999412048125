import React from 'react';
// import './Footer.css';
import { Link } from 'react-router-dom';


import MRINDIANCODER from "../images/mr.indiancoder.jpg";
import "./Footer.css";

function Footer() {
    return (


        <div className="makeupberhampore">
            <br></br>



            <div className="album py-3">

                <div className="container">

                    <div className="row">
                        <div className="col-md-3 ">

                            <h6 style={{ color: "white" }}>SHOP ADDRESS</h6>
                            <hr style={{ color: "white" }} />

                            <ul className="list-unstyled">

                                <h6 style={{ color: "white" }} className="card-text "> <b>QUEEN MAKEUP AND NAIL</b></h6>

                                <p style={{ color: "white" }} className="card-text mb-1">114/1 ac road. Indraprastha.</p>
                                <p style={{ color: "white" }} className="card-text">Po-Khagra. 742103
                                    Berhampore. Murshidabad</p>
                                <a href="tel: +91 8367802292" style={{ color: "white" }} className=" mb-3">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                </svg> +91 8367802292 </a>
                                <br />
                                <a style={{ color: 'white' }} href='mailto: suprotimdas00@gmail.com'>Email: suprotimdas00@gmail.com</a>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h6 style={{ color: "white" }}>MAKEUP</h6>
                            <hr style={{ color: "white" }} />
                            <ul className="list-unstyled">
                                <a href='Foundation' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a ><small>Foundation</small></a></li></a>
                                <a href='Eyeshadow' style={{ color: "white" }}  >
                                    <li style={{ color: "white" }} className="mb-2"><a ><small> Eyeshadow</small></a></li></a>
                                <a href='Concealer-palate' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Concealer palate </small></a></li></a>
                                <a href='Moistrizer' style={{ color: "white" }} >
                                    <li className="mb-2"><a><small>Moisturizer                                    </small></a></li></a>

                                <a href='Primer' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Primer</small></a></li></a>
                                <a href='lipe-palate' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Lipe palate</small></a></li></a>
                                <a href='Highlighter' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Highlighter</small></a></li></a>
                                <a href='blush' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Blush</small></a></li></a>
                                <a href='Fixer' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Fixer</small></a></li></a>
                                    <a href='Blender' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Blender</small></a></li></a>
                                    <a href='Compact' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Compact powder</small></a></li></a>          

                                    <a href='Toner' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Toner</small></a></li></a>                              


                                    
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h6 style={{ color: "white" }}>HAIR</h6>
                            <hr style={{ color: "white" }} />
                            <ul className="list-unstyled">
                                <a href='Hair-Machine' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a ><small>Hair machine</small></a></li></a>
                                <a href='Hair-flower' style={{ color: "white" }}  >
                                    <li style={{ color: "white" }} className="mb-2"><a ><small> Hair flower</small></a></li></a>
                                <a href='hair-dummy' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Hair dummy</small></a></li></a>
                                <a href='Eyedummy' style={{ color: "white" }} >
                                    <li className="mb-2"><a><small>Eye dummy</small></a></li></a>
                                <a href='hard-spray' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Hard spray</small></a></li></a>
                                    <a href='Moose' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Moose</small></a></li></a>
                                    <a href='hair-extensions' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Hair Extensions</small></a></li></a>
                                
                                <a href='Shine-spray' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Shine spry</small></a></li></a>

                                <a href='Texture-spray' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Texture spry</small></a></li></a>

                                <a href='bun-stuffing' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Bun & Stuffing</small></a></li></a>

                                <a href='hair-cover' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Hair cover spry</small></a></li></a>
                                    <a href='Clip-kata' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Clip & Kata</small></a></li></a>
                                    <a href='Hair-Gador' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small>Hair Gador</small></a></li></a>
                                    <a href='Spray-applicator' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><a><small> Spray plicator</small></a></li></a>
                                   
                            </ul>

                        </div>
                        <div className="col-md-3">

                            <h6 style={{ color: "white" }}>  QUICK LINK</h6>
                            <hr style={{ color: "white" }}/>

                            <ul class="list-unstyled">

                                <a href='contact-us' style={{ color: "white" }} >
                                    <li style={{ color: "white" }} className="mb-2"><small>Contact Us</small></li></a>
                        
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h6 style={{ color: "white" }}>SOCIAL LINK</h6>
                            <hr style={{ color: "white" }} />
                            <ul className="list-unstyled">
                                <li className="mb-2"> <a style={{ color: "white" }} href="https://www.facebook.com/profile.php?id=100064112051233" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "white" }} className="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg> <small> Facebook</small></a> </li>

                                <li className="mb-2"> <a style={{ color: "white" }} href="https://www.youtube.com/shorts/eel_Iv_eTLg" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style={{ color: "white" }} className="bi bi-youtube" viewBox="0 0 16 16">
                                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                    </svg> <small> Youtube</small></a> </li>

                                    <li className="mb-2"> <a  style={{ color: "white" }}  href="https://www.facebook.com/people/Queen-Makeup-Products-Jewellery/100064112051233/" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" style={{ color: "white" }}  className="bi bi-instagram"  viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.182 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.182 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.118a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                      </svg> <small> Instagram</small></a> </li>






                            </ul>

                        </div>





                    </div>
                </div>
            </div>

            <center>
                <button className="btn btn-light"> <small> Visitor Counter </small> 
                <a href="https://www.queenmakeupandnail.com" target="_blank">
<img src="https://hitwebcounter.com/counter/counter.php?page=16626999&style=0038&nbdigits=9&type=page&initCount=200" title="makeupwebsite" Alt="queen makeup and nail"   border="0"/></a>                
                </button>
            </center>


            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <div className="col-md-4">
                            <Link to="/">
                                {/* <img src={logo}  alt="logombdharandsonjewellers"/>  */}
                            </Link>
                        </div>

                        <div className="col-md-4">
                            <p style={{ color: "white" }}> Copyright © 2024, QUEEN MAKEUP AND NAIL</p>

                        </div>
                        <div className="col-md-4">
                            <small style={{ color: "white" }}> Development & Design : <a style={{ color: "white" }} href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> MR. INDIAN CODER</a> </small>
                            <small>  <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> </a> </small>
                            <a href="https://www.mrindiancoder.com/" target="_blank" rel="noopener noreferrer"> <img src={MRINDIANCODER} width="85" height="30" alt="mr.indiancoder" /> </a>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        //  </div>





    );
}
export default Footer;