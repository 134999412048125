import React from 'react'
import Navbar from '../inc/Navbar'
import Reviews from './Reviews'
import ScrollToTop from "react-scroll-to-top";
function Reviewpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="Darkblue" />
        <div className='album py-2'>
                <div className='container'>
                    <h1><b>OUR REVIEWS</b></h1>
                    </div>
                    </div>

        <Reviews/>
        <br/>
    </div>
  )
}

export default Reviewpage