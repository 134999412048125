import React from 'react'

function Map() {
  return (
    <div>
         <div className="album py-1">

<div className="container">

  <div className="row">
  <iframe className="iframe-fluid mb-3"height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Queen%20Makeup%20Products,%20Main.%20Indraprastha,%20AC%20Rd,%20near%20Lipika%20girls%20School,%20Indraprastha,%20Khagra,%20Berhampore,%20West%20Bengal%20742103%20+(Queen%20Makeup%20Products,%20Main.%20Indraprastha,%20AC%20Rd,%20near%20Lipika%20girls%20School,%20Indraprastha,%20Khagra,%20Berhampore,%20West%20Bengal%20742103)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
  <iframe src="https://www.google.com/maps/embed?pb=!4v1724836449684!6m8!1m7!1sfelto5EmtstH2icIJaemhw!2m2!1d24.10880373090354!2d88.25783799201255!3f143.17339536103302!4f-2.298997957697594!5f1.442883841967014" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</div>
</div>
    </div>
  )
}

export default Map