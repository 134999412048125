import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'


import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";
import Navbar from '../inc/Navbar';
import { Link } from "react-router-dom";

function HineSpry() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(45);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [queenmakeupberhampore, setqueenmakeupberhampore] = useState([])
    const navigate = useNavigate()

    const queenmakeupberhamporeCollectionRef = collection(db, "SHINE-Spray");
    useEffect(() => {

        const getqueenmakeupberhampore = async () => {
            const data = await getDocs(queenmakeupberhamporeCollectionRef);
            setqueenmakeupberhampore(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getqueenmakeupberhampore()
    }, [])
    return (

        <>

            <Navbar />
            <br />
            <br />
            <br />

            <br />
            <br />
            <ScrollToTop smooth top="100" color="Darkblue" />
            <div className='makeupshopmurshidabad'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Shine Spray
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
          

            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {queenmakeupberhampore.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())





                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((queenmakeupberhampore) => {
                            return (

                                <div className="col-md-4" >
                                    <div className="card cardnew mb-3">
                                        <img src={queenmakeupberhampore.img} className="card-img-top mb-0" alt={queenmakeupberhampore.title} />


                                        <div className="card-body">
                                            <div className="textcolor">
                                                <h5 style={{ color: "deeppink" }} className="card-text"> <b>{queenmakeupberhampore.title}</b></h5>
                                            </div>

                                            <p className="card-text mb-2">{queenmakeupberhampore.des}</p>


                                            <p style={{ color: "grey" }} className="card-text mb-3"><small>{queenmakeupberhampore.delivery}</small></p>


                                            <div className="d-flex justify-content-between align-items-center mb-0">
                                            <p style={{color:"grey"}}>M.R.P.: <del>{queenmakeupberhampore.mrp}</del></p>
                                            <button className='buttonper'>{queenmakeupberhampore.off}% OFF</button>
                                            </div>
                                            <h5 style={{color:"darkBlue"}}> ₹ <b>{queenmakeupberhampore.price}</b> </h5>

                                            
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href='tel: 8367802292'>
                                                    <p className="buttoncallmenu mb-2">Call Book</p></a>

                                                <ReactWhatsapp number="+91 8367802292" className="buttoncallmenu mb-2" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>


                                            </div>
                                            <p className='mb-0'> <center> <svg style={{ color: "maroon" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
                                                <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                                            </svg> Home Delivery Available</center></p>

                                        </div>

                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default HineSpry