import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'



import ReactWhatsapp from "react-whatsapp";


function VisitShop() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(45);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [queenmakeupberhampore, setqueenmakeupberhampore] = useState([])
    const navigate = useNavigate()

    const queenmakeupberhamporeCollectionRef = collection(db, "VISIT-SHOP");
    useEffect(() => {

        const getqueenmakeupberhampore = async () => {
            const data = await getDocs(queenmakeupberhamporeCollectionRef);
            setqueenmakeupberhampore(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getqueenmakeupberhampore()
    }, [])
    return (

        <>

      
            
            <br />
          

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {queenmakeupberhampore.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())





                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((queenmakeupberhampore) => {
                            return (

                                <div className="col-md-4" >
                                    <div className="card cardnew mb-3">
                                        <img src={queenmakeupberhampore.img} className="card-img-top mb-0" alt="makzamiafoundation" />


                                        <div className="card-body">
                                            <div className="textcolor">
                                                <h4 style={{ color: "deeppink" }} className="card-text"> <b>{queenmakeupberhampore.title}</b></h4>
                                            </div>

                                            <p className="card-text mb-2">{queenmakeupberhampore.des}</p>

                                           

                                            

                                        </div>

                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default VisitShop;