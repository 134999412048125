import React from 'react'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import reviewall from "../images/review page.mp4";
import pujareview from "../images/review.mp4";
import reviewspage from "../images/reviews.mp4";

import valentinereview from "../images/valentines day.mp4"
import reviewspages from "../images/review ads.mp4";
import rampurhat from "../images/rampurhat.mp4";


function Reviews() {
  return (
    <div>
      <div className="album py-3">
        <div className="container">
          <div className="row ">
            <div className="col-md-4 mt-2">

              <Video Play loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={reviewall} type="video/webm"/>
              </Video>
            </div>
            <div className="col-md-4 mt-2">

              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={pujareview} type="video/webm" />
              </Video>
            </div>

            <div className="col-md-4 mt-2">
              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={reviewspage} type="video/webm" />
              </Video>
            </div>
            <div className="col-md-4 mt-2">
              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={valentinereview} type="video/webm" />
              </Video>
            </div>

            <div className="col-md-4 mt-2">
              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={reviewspages} type="video/webm" />
              </Video>
            </div><div className="col-md-4 mt-2">
              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={rampurhat} type="video/webm"/>
              </Video>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews