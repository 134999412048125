import React from 'react'



import Ikonichairmachine from "../images/Ikonic Hair Mchine.jpg";
import hairdummy from "../images/dummy hair.jpg";
import hardspry from "../images/p hard spray.jpg";

import hinespry from "../images/Osis Shire spray.jpg";
import texturespry from "../images/kneune Texture Spray.jpg";
import coverhair from "../images/cover hair.jpg";
import moose from "../images/Nova Gold Mousse.jpg";
import waxstick from "../images/Wax stick hair.jpg";


import clip from "../images/clip hair.jpg"
import bunstuffing from "../images/bun hair.jpg";
import hairflower from "../images/Flower Hair.jpg";

import gador from "../images/gador new.jpg";
import clipbox from "../images/box clip.jpg"
import Spryapplicator from "../images/Spray app.jpg";
import eyedummy from "../images/eye dummy new.jpg";
import hairextention from "../images/hair extentions.jpg";



function Hair() {
    return (
        <div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Hair-Machine'>
                                        <div className="card shadow">
                                            <img src={Ikonichairmachine} className="card-img-top mb-0" alt="Ikonichairmachine"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Hair Machine</h4>
                                                    <a href='Hair-Machine'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='hair-dummy'>
                                        <div className="card shadow">
                                            <img src={hairdummy} className="card-img-top" alt="hairdummy"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Hair Dummy</h4>
                                                    <a href='hair-dummy'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div></a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='hard-spray'>
                                        <div className="card shadow">
                                            <img src={hardspry} className="card-img-top" alt="hardspry"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Hard Spray</h4>
                                                    <a href='hard-spray'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div></a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <a href='Shine-spray'>
                                        <div className="card shadow">
                                            <img src={hinespry} className="card-img-top" alt="hinespry"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Shine Spry</h4>
                                                    <a href='Shine-spray'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div></a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Texture-spray'>
                                        <div className="card shadow">
                                            <img src={texturespry} className="card-img-top" alt="texturespry"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Texure Spray</h4>
                                                    <a href='Texture-spray'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='hair-cover'>
                                        <div className="card shadow">
                                            <img src={coverhair} className="card-img-top " alt="haircoderspry"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Hair Cover</h4>
                                                    <a href='hair-cover'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Moose'>
                                        <div className="card shadow">
                                            <img src={moose} className="card-img-top " alt="moose"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Moose</h4>
                                                    <a href='Moose'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Wax-stick'>
                                        <div className="card shadow">
                                            <img src={waxstick} className="card-img-top " alt="waxstick"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Wax Stick</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Clip-kata'>
                                        <div className="card shadow">
                                            <img src={clip} className="card-img-top" alt="clip"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Clip & kata</h4>
                                                    <a href='Clip-kata'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='bun-stuffing'>
                                        <div className="card shadow">
                                            <img src={bunstuffing} className="card-img-top " alt="bunstuffing"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Bun & stuffing</h4>
                                                    <a href='bun-stuffing'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Hair-flower'>
                                        <div className="card shadow">
                                            <img src={hairflower} className="card-img-top " alt="hairflower"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Hair flower</h4>
                                                    <a href='Hair-flower'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Hair-extensions'>
                                        <div className="card shadow">
                                            <img src={hairextention} className="card-img-top " alt="hairextention"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Hair extension</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Hair-Gador'>
                                        <div className="card shadow">
                                            <img src={gador} className="card-img-top" alt="gador"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Gador</h4>
                                                    <a href='Hair-Gador'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Clipbox'>
                                        <div className="card shadow">
                                            <img src={clipbox} className="card-img-top " alt="clipbox"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Clip box</h4>
                                                    <a href='Clipbox'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Spray-applicator'>
                                        <div className="card shadow">
                                            <img src={Spryapplicator} className="card-img-top " alt="Spryapplicator"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Spray applicator</h4>
                                                    <a href='Spray-applicator'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Eyedummy'>
                                        <div className="card shadow">
                                            <img src={eyedummy} className="card-img-top " alt="eyedummy"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eye Dummy</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Hair