import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

function Slider() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [slider, setSlider] = useState([])
  const navigate = useNavigate()

  const sliderCollectionRef = collection(db, "SLIDER-HOME");
  useEffect(() => {

    const getSlider = async () => {
      const data = await getDocs(sliderCollectionRef);
      setSlider(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getSlider()
  }, [])
  return (
    <div>

      {slider.filter((val) => {
        if (setsearchTerm === "") {
          return val;
        } {
          return val;
        }
      }).slice(pagination.start, pagination.end).map((queenmakeupnail) => {
        return (



          <div className="card-body">

            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>




              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="radius">
                  <img src={queenmakeupnail.slider1} className="d-block w-100" alt="skillfoundationkarimpur" />
                </div>
                </div>
                <div className="radius">
                <div className="carousel-item">
                  <img src={queenmakeupnail.slider2} className="d-block w-100" alt="skillprize" />
                </div>
                </div>
                <div className="radius">
                <div className="carousel-item">
                  <img src={queenmakeupnail.slider3} className="d-block w-100" alt="somdebsirspeech" />
                </div>
                </div>

                
                
              </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>




            {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}





          </div>

        )
      })}



    </div>
  )
}

export default Slider
