
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBzqBoVzmL7aP5uIyCSCQ-c2Ohx4plhx4A",
  authDomain: "queen-makeup-c6516.firebaseapp.com",
  projectId: "queen-makeup-c6516",
  storageBucket: "queen-makeup-c6516.appspot.com",
  messagingSenderId: "982684707091",
  appId: "1:982684707091:web:e281b7b73a4ef4efba92e6",
  measurementId: "G-972C7VVH1T"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;