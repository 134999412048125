import React from 'react'
import Hudabeauty from "../images/Huda Beauty.jpg";
import nars from "../images/NARS LOGO.jpg";
import Mac from "../images/mac logo.jpg";
import Ickonic from "../images/ickonic logo.jpg"

import Mackzamia from "../images/Makzemia logo.jpg"
import Recode from "../images/Recode.jpg";


import Forever52 from "../images/Forever52.jpg"
import Carnival from "../images/carnival logo.jpg"
import shoparel from "../images/sHOPAREL.jpg"
import afflarisa from "../images/Afflarisa.jpg";


function Brand() {
    return (
        <div>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='huda-brand'>
                                        <div className="card" >
                                            <img src={Hudabeauty} className="card-img-top" alt="Hudabeauty" />

                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 me-2 mt-3">
                                    <div className="card" >
                                        <img src={nars} className="card-img-top" alt="nars" />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <div className="card" >
                                        <img src={Mac} className="card-img-top" alt="Mac" />

                                    </div>
                                </div>

                                <div className="col-md-6 me-2 mt-3">
                                    <a href='ikonic-brand'>
                                    <div className="card" >
                                        <img src={Ickonic} className="card-img-top" alt="Ickonic"/>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className="album py-1">
                            <div className="container">
                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-start align-items-center mb-2">
                                            <div className="col-md-6 me-2 mt-3">
                                                <a href='MakezmiaBrand'>
                                                <div className="card" >
                                                    <img src={Mackzamia} className="card-img-top" alt="Mackzamia" />

                                                </div>
                                                </a>
                                            </div>

                                            <div className="col-md-6 me-2 mt-3">
                                                <div className="card" >
                                                    <img src={Recode} className="card-img-top" alt="Recode" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-start align-items-center mb-2">
                                            <div className="col-md-6 me-2 mt-3">
                                                <div className="card" >
                                                    <img src={Forever52} className="card-img-top" alt="Forever52" />

                                                </div>
                                            </div>
                                            <div className="col-md-6 me-2 mt-3">
                                                <a href='carnival-brand'>
                                                <div className="card" >
                                                    <img src={Carnival} className="card-img-top" alt="Carnival" />

                                                </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="d-flex justify-content-start align-items-center mb-2">
                        <div className="col-md-6 me-2 mt-3">
                            <div className="card" >
                                <img src={shoparel} className="card-img-top" alt="shoparel"/>

                            </div>
                        </div>
                        <div className="col-md-6 me-2 mt-3">
                            <div className="card" >
                                <img src={afflarisa} className="card-img-top" alt="afflarisa"/>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Brand