import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'



import ReactWhatsapp from "react-whatsapp";

import ReactPlayer from "react-player";

function YoutubeV(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showPerPage, setShowPerPage] = useState(9);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  const [searchTerm, setsearchTerm] = useState("");
  const [videop, setVideop] = useState([])
  const navigate = useNavigate()

  const videopCollectionRef = collection(db, "VIDEO YOUTUBE");
  useEffect(() => {

    const getVideop = async () => {
      const data = await getDocs(videopCollectionRef);
      setVideop(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    getVideop()
  }, [])
  return (

    <>





      <br />

      <div className="album py-0">

      
      </div>
      <div className="album py-4">

        <div className="container">

          <div className="row">
            {videop.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
             



              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((queenmakeup) => {
              return (

                <div className="col-md-4 mt-8" >
                  <div className=" mb-4">
                    <ReactPlayer className="mb-2" width="100%" height="100%" url={queenmakeup.Vedioyoutube} />

                  </div>

                </div>
              )
            })}
          </div>
      
        </div>
      </div>

    </>

  );
}

export default YoutubeV;