
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Foundation from './components/pages/Foundation';
import Eyeshadow from './components/pages/Eyeshadow';
import Reviewpage from './components/pages/Reviewpage';
import ConPalate from './components/pages/ConPalate';
import Visitpage from './components/pages/Visitpage';
import Moistraizer from './components/pages/Moistrizer';
import Primer from './components/pages/Primer';
import LipeP from './components/pages/LipeP';
import Highlighter from './components/pages/Highlighter';
import Blush from './components/pages/Blush';
import HairMachine from './components/pages/HairMachine';
import HairD from './components/pages/HairD';
import HairSpry from './components/pages/HairSpry';
import HineSpry from './components/pages/HineSpry';
import TextureS from './components/pages/TextureS';
import HairCover from './components/pages/HairCover';
import Moose from './components/pages/Moose';
import WaxStick from './components/pages/WaxStick';
import Clip from './components/pages/Clip';
import Bun from './components/pages/Bun';
import HairFlower from './components/pages/HairFlower';
import HairExten from './components/pages/HairExten';
import Gador from './components/pages/Gador';
import Clipbox from './components/pages/Clipbox';
import SprayApplicator from './components/pages/SprayApplicator';
import Eyedummy from './components/pages/Eyedummy';
import Fixer from './components/pages/Fixer';
import Blender from './components/pages/Blender';
import Compact from './components/pages/Compact';
import Toner from './components/pages/Toner';
import HudaBrand from './components/pages/HudaBrand';
import MakzamiaBrand from './components/pages/MakzamiaBrand';

import Ikonic from './components/pages/Ikonic';
import CarnivalBrand from './components/pages/CarnivalBrand';
import Brushset from './components/pages/Brushset';
import Magicdrop from './components/pages/Magicdrop.js';
import Eyelash from './components/pages/Eyelash.js';
import ShimmerP from './components/pages/ShimmerP.js';
import LensProduct from './components/pages/LensProduct.js';
import BrushCleaner from './components/pages/BrushCleaner.js';
import EyeKajol from './components/pages/EyeKajol.js';

function App() {
  return (
    <Router>
    
    <div>

      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/Foundation" element={<Foundation/>}/>
      <Route axact path="/Eyeshadow" element={<Eyeshadow/>}/>
      <Route axact path="/Reviewpage" element={<Reviewpage/>}/>
      <Route axact path="/Concealer-palate" element={<ConPalate/>}/>
      <Route axact path="/Visit-shop" element={<Visitpage/>}/>
      <Route axact path="/Moistraizer" element={<Moistraizer/>}/>

            <Route axact path="/Primer" element={<Primer/>}/>
            <Route axact path="/lipe-palate" element={<LipeP/>}/>
            <Route axact path="/Highlighter" element={<Highlighter/>}/>
            <Route axact path="/blush" element={<Blush/>}/>
            <Route axact path="/hair-dummy" element={<HairD/>}/>
            <Route axact path="/hard-spray" element={<HairSpry/>}/>
            <Route axact path="/Hair-Machine" element={<HairMachine/>}/>
            <Route axact path="/Shine-spray" element={<HineSpry/>}/>
            <Route axact path="/Texture-spray" element={<TextureS/>}/>
            <Route axact path="/hair-cover" element={<HairCover/>}/>
            <Route axact path="/Moose" element={<Moose/>}/>
            <Route axact path="/Wax-stick" element={<WaxStick/>}/>
            <Route axact path="/Clip-kata" element={<Clip/>}/>    
            <Route axact path="/bun-stuffing" element={<Bun/>}/> 
            <Route axact path="/Hair-flower" element={<HairFlower/>}/> 
            <Route axact path="/Hair-extensions" element={<HairExten/>}/> 
            <Route axact path="/Hair-Gador" element={<Gador/>}/> 
            <Route axact path="/Clipbox" element={<Clipbox/>}/> 
            <Route axact path="/Spray-applicator" element={<SprayApplicator/>}/> 
            <Route axact path="/Eyedummy" element={<Eyedummy/>}/> 
            <Route axact path="/Fixer" element={<Fixer/>}/> 
            <Route axact path="/Blender" element={<Blender/>}/> 
            <Route axact path="/Compact" element={<Compact/>}/> 
            <Route axact path="/Toner" element={<Toner/>}/> 

            <Route axact path="/huda-brand" element={<HudaBrand/>}/> 
            
            <Route axact path="/MakezmiaBrand" element={<MakzamiaBrand/>}/> 
            <Route axact path="/ikonic-brand" element={<Ikonic/>}/> 
            <Route axact path="/carnival-brand" element={<CarnivalBrand/>}/> 
            <Route axact path="/Brushset" element={<Brushset/>}/> 
            <Route axact path="/Magicdrop" element={<Magicdrop/>}/> 
            <Route axact path="/Eyelash" element={<Eyelash/>}/> 
            <Route axact path="/Shimmer-palette" element={<ShimmerP/>}/> 
            <Route axact path="/lens" element={<LensProduct/>}/> 
            <Route axact path="/Brush-cleaner" element={<BrushCleaner/>}/> 
            <Route axact path="/Eye-kajol" element={<EyeKajol/>}/> 
      
      </Routes>
    <Footer/>
    </div>
    </Router>
  );
}

export default App;

