import React from 'react'



import huabeautyfoundation from "../images/huda b foundation.jpg";
import carnivaleyeshadow from "../images/carnival eyeshadow.jpg";
import shoparelConcealerpalette from "../images/con palate.jpg";
import MoistrizerShaparelred from "../images/Moistrizer Red.jpg";
import hudaprimer from "../images/Huda primer jely.jpg";
import lipepalate from "../images/Lipe plate.jpg";
import Highlight from "../images/Highlighter ofra.jpg";
import blush from "../images/imagic Blush.jpg";
import fixertoner from "../images/fixer.jpg";
import maccompact from "../images/mac compact.jpg"
import Tonerpixi from "../images/toner pixi.jpg";
import blender from "../images/blender imagci.jpg"
import Brushset from "../images/beili brush set.jpg";
import Magicdropaflairza from "../images/Magic Drops.jpg";
import Eyeglashglue from "../images/Eye lash glue.jpg"
import shimmerpalette from "../images/Shimmer palette.jpg"
import Aqualens from "../images/agua.jpg";
import Brushcleaner from "../images/brush cleaner.jpg";
import Eyeblackjailkajolimagic from "../images/Eye black jail kajol Imagic.jpg";
import eyelashimagic from "../images/Eye Lash Imagic.jpg"
import EyebrowImagic from "../images/Eyebrow Imagic.jpg"
import EyelinerRecode from "../images/Eyeliner Recode.jpg"
import Mascara from "../images/Ferrarucci Mascara.jpg";
import Gittermit from "../images/Giiter mit.jpg";
import Gittergluimagic from "../images/Gitter glue imagic.jpg"
import Lipliner from "../images/IMAGIC PROfessional GLODEN SILKY LIP LINER.jpg"
import Lenssolutionaqua from "../images/Lens Solution Aqua.jpg";
import Lipstic from "../images/Lipstic Foreever52.jpg";
import Ringlight from "../images/Ring light digitek 19rt.jpg"
import Serumrecode from "../images/Serum recode.jpg";
import ShillMakeup from "../images/Shill Makeup.jpg";
import StrobecreamMAC from "../images/Strobe cream mac.jpg";




function Makeup() {
    return (
        <div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Foundation'>
                                        <div className="card shadow">
                                            <img src={huabeautyfoundation} className="card-img-top mb-0" alt="huabeautyfoundation" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Foundation</h4>
                                                    <a href='Foundation'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Eyeshadow'>
                                        <div className="card shadow">
                                            <img src={carnivaleyeshadow} className="card-img-top" alt="carnivaleyeshadow"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eyeshadow</h4>
                                                    <a href='Eyeshadow'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div></a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Concealer-palate'>
                                        <div className="card shadow">
                                            <img src={shoparelConcealerpalette} className="card-img-top" alt="Concealerpalette" />
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Concealer palate</h4>
                                                    <a href='Concealer-palate'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div></a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <a href='Moistraizer'>
                                        <div className="card shadow">
                                            <img src={MoistrizerShaparelred} className="card-img-top" alt="MoistrizerShaparelred"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Moisturizer</h4>
                                                    <a href='Moistraizer'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div></a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Primer'>
                                        <div className="card shadow">
                                            <img src={hudaprimer} className="card-img-top" alt="hudaprimer"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Primer</h4>
                                                    <a href='Primer'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='lipe-palate'>
                                        <div className="card shadow">
                                            <img src={lipepalate} className="card-img-top " alt="lipepalate"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Lipe palate</h4>
                                                    <a href='lipe-palate'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Highlighter'>
                                        <div className="card shadow">
                                            <img src={Highlight} className="card-img-top " alt="Highlight" />

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Highlighter</h4>
                                                    <a href='Highlighter'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='blush'>
                                        <div className="card shadow">
                                            <img src={blush} className="card-img-top " alt="blush"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Blush</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Fixer'>
                                        <div className="card shadow">
                                            <img src={fixertoner} className="card-img-top" alt="fixertoner"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Fixer</h4>
                                                    <a href='Fixer'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Compact'>
                                        <div className="card shadow">
                                            <img src={maccompact} className="card-img-top " alt="maccompact"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Compact</h4>
                                                    <a href='Compact'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Toner'>
                                        <div className="card shadow">
                                            <img src={Tonerpixi} className="card-img-top " alt="Tonerpixi"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Toner</h4>
                                                    <a href='Toner'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Blender'>
                                        <div className="card shadow">
                                            <img src={blender} className="card-img-top " alt="blenderblush"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Blender</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        


            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Brushset'>
                                        <div className="card shadow">
                                            <img src={Brushset} className="card-img-top" alt="Brushset"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Brush Set</h4>
                                                    <a href='Brushset'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Magicdrop'>
                                        <div className="card shadow">
                                            <img src={Magicdropaflairza} className="card-img-top " alt="Magicdropaflairza"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Magic Drop</h4>
                                                    <a href='Magicdrop'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Eyelash'>
                                        <div className="card shadow">
                                            <img src={Eyeglashglue} className="card-img-top " alt="Eyeglashglue"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eye Lash Glu</h4>
                                                    <a href='Eyelash'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Shimmer-palette'>
                                        <div className="card shadow">
                                            <img src={shimmerpalette} className="card-img-top " alt="shimmerpalette"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Shimmer Palette</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='lens'>
                                        <div className="card shadow">
                                            <img src={Aqualens} className="card-img-top" alt="Aqualens"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Lens Aqua</h4>
                                                    <a href='lens'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Brush-cleaner'>
                                        <div className="card shadow">
                                            <img src={Brushcleaner} className="card-img-top " alt="Brushcleaner"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Brush Cleaner</h4>
                                                    <a href='Brush-cleaner'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Eye-kajol'>
                                        <div className="card shadow">
                                            <img src={Eyeblackjailkajolimagic} className="card-img-top " alt="Eyeblackjailkajolimagic"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eye  Kajol</h4>
                                                    <a href='Eye-kajol'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={eyelashimagic} className="card-img-top " alt="eyelashimagic"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eye Lash Imagic</h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={EyebrowImagic} className="card-img-top" alt="EyebrowImagic"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eye Brow </h4>
                                                    <a href='Brushset'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={EyelinerRecode} className="card-img-top " alt="EyelinerRecode"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Eye Liner </h4>
                                                    <a href='Magicdrop'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Mascara} className="card-img-top " alt="Mascara"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Mascara</h4>
                                                    <a href='Eyelash'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Gittermit} className="card-img-top " alt="Gittermit"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Gitter </h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Gittergluimagic} className="card-img-top" alt="Gittergluimagic"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Gitter Glu </h4>
                                                    <a href='Brushset'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Lipliner} className="card-img-top " alt="Lipliner"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Lip Liner </h4>
                                                    <a href='Magicdrop'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Lenssolutionaqua} className="card-img-top " alt="Lenssolutionaqua"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Lens Solution</h4>
                                                    <a href='Eyelash'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Lipstic} className="card-img-top" alt="GLipstic"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Lipstic </h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                
                
                    </div>
                </div>
            </div>


            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Ringlight} className="card-img-top" alt="Ring Light"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Ring Light Glu </h4>
                                                    <a href='Brushset'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>

                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={Serumrecode} className="card-img-top" alt="Serumrecode"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Serum  </h4>
                                                    <a href='Magicdrop'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={ShillMakeup} className="card-img-top " alt="ShillMakeup"/>

                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Shill Makeup</h4>
                                                    <a href='Eyelash'>
                                                        <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                            <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                        </svg></small> </div>
                                                    </a>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card shadow">
                                            <img src={StrobecreamMAC} className="card-img-top" alt="StrobecreamMAC"/>
                                            <center>
                                                <div className="card-body">
                                                    <h4 style={{ color: "black" }} className="card-title mb-2">Strobe Cream </h4>
                                                    <div className="buttonnew" type="submit"><small>View Details <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                                    </svg></small> </div>
                                                </div>
                                            </center>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                
                
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Makeup